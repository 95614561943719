/*
Copyright The Linux Foundation and each contributor to LFX.
SPDX-License-Identifier: MIT
*/

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url(poppins-400.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url(poppins-600.ttf) format('truetype');
}
