/*
Copyright The Linux Foundation and each contributor to LFX.
SPDX-License-Identifier: MIT
*/

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(source-sans-pro-400.ttf) format('truetype');
}
