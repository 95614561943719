// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

/* You can add global styles to this file, and also import other style files */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './assets/fonts/font-open-sans.css';
@import './assets/fonts/font-poppins.css';
@import './assets/fonts/font-source-sans-pro.css';
@import '../node_modules/swiper/swiper-bundle.css';

// Common CSS
[lfxTooltip] {
  @apply relative;
}

.tooltip {
  @apply absolute p-1.5 z-50 font-normal shadow-tile font-open-sans bg-white border-1 border-gray-400 w-56 lg:w-72 text-xs-11 bottom-full text-gray-5353 rounded-lg;
  &.right {
    @apply right-0;
  }
  &.left {
    @apply left-0;
  }
}

.tab-error {
  @apply bg-white border-1 border-gray-0e7 text-xs p-2.5 text-center;
}

// custom scroll bar
.lfx-custom-scroll {
  // For Chrome
  &::-webkit-scrollbar {
    @apply w-[5px];
  }

  /* Track */
  &::-webkit-scrollbar-track {
    @apply rounded-10px bg-clip-content bg-white;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    @apply border-0 rounded-[100px] min-h-50px bg-lf-gray-0e0 border-t-[5px] border-white;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    @apply bg-lf-gray-0e0;
  }
}

.scrollbar-hide {
  &::-webkit-scrollbar {
    @apply hidden;
  }
}

.scrollbar-default {
  &::-webkit-scrollbar {
    @apply block;
  }
}

// popover
.popover__content {
  @apply absolute inline-block  translate-x-m130px translate-y-10px shadow-popover-content visible z-10	max-h-300px overflow-y-auto w-250px bg-white border border-gray-400 rounded-md;
}

.tooltip_content {
  @apply absolute inline-block text-xs p-2 translate-y-30px shadow-popover-content z-10 break-all font-normal bg-white border border-gray-400 rounded-md;
  span {
    @apply block;
  }
}

// Toaster
.toast-container {
  .toast-close-button {
    @apply top-0;
  }

  .toast-message {
    @apply text-left;
  }

  .ngx-toastr {
    @apply font-open-sans text-center text-base;

    &.toast-info {
      @apply mt-[58px] bg-lf-sky-blue py-2.5	px-5 mx-auto;

      .toast-message {
        @apply ml-5px text-center;
      }
    }

    &.toast-error {
      @apply bg-lf-red-error-toast;
    }

    &.toast-success {
      @apply bg-lf-green-success-toast;
    }
  }
}

.toaster-title {
  @apply text-left font-semibold;
}

.toaster-message {
  @apply text-xs text-left;
}

/*Added support for hover title on mobile screen*/
[title] {
  @apply text-xs relative rounded-lg;
}
body.touched [title] > * {
  user-select: none;
}
body.touched [title]:hover > * {
  user-select: auto;
}
body.touched [title]:hover:after {
  @apply z-10 py-1 px-2 bg-white absolute top-full border-1 border-gray-0e7;
  left: 10%;
  content: attr(title);
}

.ngxp__container{
  @apply z-50 font-normal shadow-tile font-open-sans bg-white border-1 border-gray-400 w-56 lg:w-72 text-xs-11 bottom-full text-gray-5353 rounded-lg;

  &[data-popper-placement^='top'] .ngxp__arrow:before {
    @apply border-1 mt-[2px] border-l-0 border-t-0 border-gray-400 border-solid;
  }

  &[data-popper-placement^='bottom'] .ngxp__arrow:before {
    @apply border-1 mt-[2px] border-r-0 border-b-0 border-gray-400 border-solid;
  }

  &[data-popper-placement^='left'] .ngxp__arrow:before {
    @apply border-1 mt-[2px] border-b-0 border-l-0 border-gray-400 border-solid;
  }

  &[data-popper-placement^='right'] .ngxp__arrow:before {
    @apply border-1 mt-[2px] border-t-0 border-r-0 border-gray-400 border-solid;
  }
}
